:root {
  --primary: #FF0091;
  --primary-hover: #ff26a2;
}

html,
body {
  /* height: 100%; */
  min-height: 100vh;
}

#root {
  /* height: 100%; */
  min-height: 100vh;
  display: grid;
}

#root>.ant-layout {
  height: 100%;
  min-height: 100%;
}

#root>.login-form {
  width: 50%;
  margin: auto;
  padding: 12px 48px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

button[type=submit] {
  display: block;
  width: 100%;
  margin: 3% auto;
  color: #fff;
  font-weight: 600;
}

button[type=submit]:hover {
  color: #fff;
}

.logo {
  height: 125px;
  display: block;
  margin: 0 auto;
}


button[type=submit],
.ant-btn-primary {
   background-color: var(--primary);
   border-color: var(--primary);
}

button[type=submit]:hover,
.ant-btn-primary:hover {
   background-color: var(--primary-hover);
   border-color: var(--primary-hover);
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--primary);
  color: var(--primary);
}

.anticon-close-circle {
  color: red;
}

.anticon-check-circle {
  color: green;
}

.ant-menu-light  .ant-menu-submenu-title:hover, .ant-menu-light .ant-menu-item:hover {
  color: var(--primary-hover);
}

.ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: var(--primary-hover);
}
